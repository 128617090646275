.VIOverViewBar {
  position: relative;
  border-bottom: 1px solid var(--system-stroke, #e8e9eb);
  .itemBx {
    position: relative;
    padding: 6px 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
    color: var(--text-text-2, #3c4376);
    cursor: pointer;
    &.active {
      color: var(--primary-blue, #304ffd);
    }
  }
  .marker {
    position: absolute;
    height: 3px;
    border-radius: 3px;
    bottom: 0;
    background: var(--primary-blue, #304ffd);
    transition: 0.5s;
  }
}
