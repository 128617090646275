.KTAdvanceNav {
  @media only screen and (min-width: 1201px) {
    ul {
      width: 250px !important;
    }
    .KTAdvanceNav_Colapse {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    .KTAdvanceNav_Expand {
      display: none !important;
    }
    .navi-link {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    button > span {
      display: none !important;
    }

    &:hover {
      // ul {
      //   width: 250px !important;
      // }
      // .navi-text {
      //   display: inline !important;
      // }
      // button > span {
      //   display: inline !important;
      // }
    }
  }
}
