.TreeCompany {
  .accordion-button {
    padding: 0px;
    font-style: normal;
    font-weight: 600;
    width: 17px !important;
    height: 17px !important;

    line-height: 17px;
    color: #4a5677 !important;

    &:focus {
      box-shadow: none !important;
    }
    &::after {
      background-image: url('../../../../../../../assets/icons/square-plus.svg');
      background-size: 16px 17px;
    }
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url('../../../../../../../assets/icons/square-minus.svg');
    background-size: 16px 17px;
    transform: revert;
  }
  .accordion-button:not(.collapsed) {
    background-color: #fff !important;
    box-shadow: none !important;
  }
  .accordion-item {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
  }

  .btn {
    border-radius: 0px !important;
  }
  .DepartmentItem {
    .accordion-button {
      display: none !important;
    }
  }
}
