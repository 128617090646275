.VISearchField {
  .form-control {
    border-radius: 12px;

    &:focus {
      border-color: #304ffd;
      box-shadow: 0px 0px 6px 0px #40afffcc !important;
    }
  }

  .icon-right {
    left: auto;
    right: 2px;
  }
}
