.dropdown-filter-invoice {
  .dropdown-toggle {
    background-color: transparent !important;
    border-color: #dde5ee !important;
    border-radius: 14px;
    color: #1c2256 !important;
    &::after {
      display: none !important;
    }
    &:focus {
      border-color: #304ffd !important;
      box-shadow: 0px 0px 6px 0px rgba(64, 175, 255, 0.8) !important;
      outline: none !important;
    }
  }
  .btn-resets {
    margin-right: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .mt-screen {
    margin-top: 1rem !important;
  }
}
@media screen and (max-width: 470px) {
  .button-filter {
    flex-direction: column !important;
  }
  .btn-resets {
    margin-right: 0 !important;
  }
  .btn-submit-filter {
    justify-content: space-between !important;
    margin-top: 0.75rem;
  }
}
