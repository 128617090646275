@import "./mixin/index";

// Change tooltip arrow color - bootstrap 5
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: white;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: white;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: white;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: white;
}

// Bootstrap DatetimePicker Widget
.bootstrap-datetimepicker-widget table tbody {
  border-top: 0 !important;
}

// Bootstrap button active
// .btn {
//   border-color: transparent !important;
// }

// Date range picker
.daterangepicker {
  padding: 0;
  margin: 0;
  width: auto;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border-radius: 0.42rem;
  border: 0;
  font-family: Poppins, Helvetica, "sans-serif";
  z-index: 98;
}
.modal-open .daterangepicker {
  z-index: 1051;
}
.daterangepicker:after,
.daterangepicker:before {
  display: none;
}
.daterangepicker .ranges ul {
  padding: 1rem 0;
  width: 175px;
}
.daterangepicker .ranges li {
  padding: 0.7rem 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: #7e8299;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.daterangepicker .ranges li:hover,
.daterangepicker .ranges li.active {
  background-color: #f3f6f9;
  color: #3699ff;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ebedf3;
  margin-top: 0;
  height: 297px;
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 0;
}
.daterangepicker .drp-buttons {
  padding: 1rem 1.75rem;
  border-top: 1px solid #ebedf3;
}
.daterangepicker .drp-buttons .btn {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.42rem;
}
.daterangepicker .drp-selected {
  font-size: 0.9rem;
}
.daterangepicker .drp-calendar.left,
.daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}
.daterangepicker .drp-calendar.left {
  border-left: 0 !important;
}
.daterangepicker .drp-calendar th,
.daterangepicker .drp-calendar td {
  font-size: 1rem;
  font-weight: regular;
  width: 33px;
  height: 33px;
}
.daterangepicker .drp-calendar th {
  font-weight: 500;
  color: #3f4254;
}
.daterangepicker .drp-calendar th.month {
  font-weight: 500;
  color: #3f4254;
}
.daterangepicker .drp-calendar th.next span,
.daterangepicker .drp-calendar th.prev span {
  border-width: 0 1px 1px 0;
  border-color: #7e8299;
}
.daterangepicker .drp-calendar th.next span {
  margin-right: 1px;
}
.daterangepicker .drp-calendar th.prev span {
  margin-left: 1px;
}
.daterangepicker .drp-calendar td {
  color: #7e8299;
}
.daterangepicker .drp-calendar td:hover {
  background-color: #f3f6f9;
}
.daterangepicker .drp-calendar td.available.off {
  color: #b5b5c3;
}
.daterangepicker .drp-calendar td.active {
  background-color: #3699ff !important;
  color: #ffffff !important;
  border-radius: 0.42rem;
}
.daterangepicker .drp-calendar td.active.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.daterangepicker .drp-calendar td.active.end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.daterangepicker .drp-calendar td.active.start-date.end-date {
  border-radius: 0.42rem;
}
.daterangepicker .drp-calendar td.today,
.daterangepicker .drp-calendar td.today.active {
  border-radius: 0.42rem;
  background: rgba(54, 153, 255, 0.12) !important;
  color: #3699ff !important;
}
.daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today) {
  background-color: #f3f6f9;
  color: #3f4254;
}
.daterangepicker select {
  border-radius: 0.42rem;
  background: transparent !important;
  border-color: #e4e6ef !important;
  color: #3f4254 !important;
}

@media (min-width: 730px) {
  .daterangepicker.show-calendar .ranges {
    height: 297px !important;
  }
}

@media (max-width: 767.98px) {
  .daterangepicker.show-calendar .ranges {
    height: 245px;
  }
}

.example-highlight pre {
  &:focus-visible {
    outline: none;
  }
}

// Form input feedback visible
.invalid-feedback {
  display: block !important;
}

.valid-feedback {
  display: block !important;
}

// Disable body document scroll
.body-overflow-hidden {
  overflow: hidden !important;
}

// Enable body document scroll
.body-overflow-auto {
  overflow: auto !important;
}

// Remove input [type=number] caret up down
.no-caret-updown-input {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}
// ---end---

// Custom scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e1f0ff;
}

// KTBlockUI
.blockui {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: normal;
}

.blockui-overlay {
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
}
.button-close {
  background: var(--system-grey, #f5f5f5);
  border-radius: 10px;
  color: var(--text-text-1, #1c2256);
}
.button-close:hover {
  background: var(--system-grey, #ebebeb);
}
.text-grey {
  color: #1c2256 !important;
}

.dropdown-menu {
  border-radius: 14px !important;
}

.navi .navi-item .navi-link .navi-icon i {
  color: #3c4376;
}

.aside-menu .menu-nav > .menu-item {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside > .aside-support {
    display: none;
  }
}

.header-dropdown {
  .dropdown-toggle:after {
    display: none !important;
  }
}

// .btn {
//   border-radius: 14px !important;
// }

.border {
  border: 1px solid #e8e9eb !important;
}

.rdt_TableHead {
  z-index: 2 !important;
}

.vi-btn {
  border-radius: 14px !important;
}

.bs-tooltip-top {
  padding: 0px !important;
}

.btn.btn-clean i {
  color: #3c4376;
}

.tooltip-inner {
  max-width: 30vw !important;
  margin-top: -4px !important;
}

.daterangepicker {
  margin-top: 3px;
}

.text-maxline-1 {
  @include maxLine(1);
}
.text-maxline-2 {
  @include maxLine(2);
}
.text-maxline-3 {
  @include maxLine(3);
}
.text-maxline-4 {
  @include maxLine(4);
}

.swal2-icon {
  margin: 2.5em auto 0.6em !important;
}

.rdt_TableCol_Sortable > div {
  white-space: normal !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
