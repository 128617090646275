.menu-arrow::before {
  content: "\f105" !important;
  font-family: "Font Awesome 5 Pro" !important;
  font-size: 1rem !important;
}

// Aside menu
.aside {
  &.aside-left {
    padding: 0px 8px;
    background: #182748 !important;
  }
  .aside-menu,
  .brand {
    background: #182748 !important;
  }
  .menu-item > .menu-link .menu-text {
    color: #ffffff !important;
    font-size: 14px !important;
  }
}
.aside-menu .menu-nav > .menu-item.menu-item .menu-link {
  border-radius: 12px;
  padding: 9px 20px;
}

.aside-menu .menu-nav > .menu-item.menu-item-active {
  & > .menu-link .menu-icon > i {
    color: #00bbb3 !important;
  }
  & > .menu-link {
    background: #334670;
  }
}

.aside-menu .menu-nav > .menu-item:hover {
  & > .menu-link .menu-icon > i {
    color: #00bbb3 !important;
  }
  & > .menu-link {
    background: #334670 !important;
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon > i {
  color: #00bbb3 !important;
}

// Brand toggle
.brand-toggle {
  &:hover > .svg-icon > img {
    filter: invert(45%) sepia(10%) saturate(5000%) hue-rotate(150deg) brightness(130%) contrast(150%) !important;
    transition: all 0.15s ease;
  }
}
.brand-toggle.active {
  & > .svg-icon > img {
    filter: invert(45%) sepia(10%) saturate(5000%) hue-rotate(150deg) brightness(130%) contrast(150%) !important;
    transition: all 0.15s ease;
    transform: rotate(180deg);
  }
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside {
    .aside-support {
      display: none !important;
    }
  }
}
