.Modal-resize {
  .modal-dialog {
    margin: 0 !important;
    width: 100vw !important;
    min-width: 100vw !important;
    min-height: 100vh !important;
  }
  p {
    color: var(--text-text-2, #3c4376);
    font-size: 14px;
    font-weight: 700;
  }
  .children-p {
    color: var(--text-text-3, #7177a8);
    // font-size: 14px;
    font-weight: 500;
    // min-width: max-content;
    // width: 100%;
  }
  .item-file {
    &:hover {
      .hover-action {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 980px) {
    .pdf-default {
      display: none !important;
    }
    .right-deital-mobile {
      display: block !important;
    }
  }
  .tippy-tooltip {
    border-radius: 20px;
    border: 1px solid var(--system-stroke, #e8e9eb);
    background: var(--system-white, #fff);
    box-shadow: 0px 5px 10px 0px rgba(44, 63, 88, 0.1);
  }
}
