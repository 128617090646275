.VITableRowDropDown {
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    border: none !important;
  }

  .dropdown-toggle.nav-link:after,
  .dropdown-toggle.btn:after {
    display: none !important;
  }
}
