.KTBSDropdown {
  .dropdown-item:hover,
  .dropdown-item:focus {
    // &:not(.active) {
    background-color: #f5f5f5 !important;
    color: #181c32 !important;
    // }
    border-radius: 12px !important;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    border-radius: 12px !important;
    color: #5180fb !important;
    background-color: white;
  }

  .KTBSDropdown .dropdown-item:hover,
  .KTBSDropdown .dropdown-item:focus {
    background-color: #f5f5f5 !important;
    color: #181c32 !important;
  }
}
