.Add-label {
  .label-color {
    .dropdown-toggle {
      // display: none !important;
      // opacity: 0 !important;
    }
    .form-check {
      margin-right: 15px;
    }
    .form-check-input {
      margin-left: -16px !important;
      margin-top: -1.5px;
    }
    .pen-color {
      width: 24px;
      height: 24px;
    }
    .btn-addColor {
      width: 24px;
      height: 24px;
      border-radius: 50%;

      .form-control {
        height: 100% !important;
        width: 100%;
      }
      .form-label {
        opacity: 0;
      }
      .form-control-color {
        border-radius: 50% !important;
      }
      .add-btn {
        display: flex;
        align-items: center;
        font-size: 15px;
        padding: 4px 5px;
        border-radius: 50%;
        left: 0;
        top: 0;
        color: #7177a8;
        background-color: #e9f0f8;
        cursor: pointer;
        &:hover {
          background-color: #dfe6ee;
        }
      }
    }
  }
}
