.transition {
  border: 1px solid #e4e6ef;
  border-radius: 12px;
  &:focus,
  &:focus-visible {
    border-color: #304ffd;
    box-shadow: 0px 0px 6px 0px rgba(64, 175, 255, 0.8) !important;
    outline: none !important;
  }
}
