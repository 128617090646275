.KeenSelectOption {
  .dropdown {
    .btn:not(.form-invalid) {
      border: 1px solid #e8e9eb !important;
    }
    .btn.show {
      border: 1px solid #304ffd !important;
      box-shadow: 0px 0px 6px 0px #40afffcc !important;
    }
  }

  .dropdown-item:active {
    color: white !important;
    background-color: #f5f5f5 !important;
  }

  .dropdown-item:hover {
    background-color: #f5f5f5 !important;
    color: #181c32 !important;
  }

  .form-invalid {
    border-color: #e54135 !important;
    box-shadow: 0px 0px 6px 0px #e54135cc !important;
  }
}
