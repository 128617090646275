.CKEditorDocument {
  .ck-toolbar {
    border-radius: 0px !important;
  }
  .ck-content {
    border-color: #ccced1;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-top-color: transparent !important;
    // border-top-width: 0;
  }
}
