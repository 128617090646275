.ManualDataEntry {
  .modal-dialog {
    margin: 0 !important;
    width: 100vw !important;
    min-width: 100vw !important;
    min-height: 100vh !important;
  }
  p {
    color: var(--text-text-2, #3c4376);
    font-size: 14px;
    font-weight: 700;
  }
  .children-p {
    color: var(--text-text-3, #7177a8);
    font-size: 14px;
    font-weight: 500;
    min-width: max-content;
    // width: 100%;
  }
  @media screen and (max-width: 600px) {
    .pdf-default {
      display: none !important;
    }
    .right-detail-mobile {
      display: block !important;
    }
  }
}
