.HelpComponentView {
  border-radius: 14px;
  &_image {
    aspect-ratio: 16/9;
  }
  img {
    border-radius: 12px;
    aspect-ratio: 16/9;
  }
  .carousel-caption {
    position: unset;
    color: #1c2256;
    text-align: justify;
  }
  .carousel-control-next,
  .carousel-control-prev,
  .carousel-indicators {
    display: none;
  }

  @media (max-width: 576px) {
    &_image {
      width: 100% !important;
    }
  }
  @media (min-width: 576px) and (max-width: 1439px) {
    &_image {
      width: 35% !important;
    }
  }

  @media (min-width: 1440px) {
    &_image {
      width: 25% !important;
    }
  }
}
