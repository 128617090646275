.Name-Check {
  display: block;
  display: -webkit-box;
  height: 16px * 1 * 2;
  line-height: 1.3;
  -webkit-line-clamp: 2; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}
