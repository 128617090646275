.menu-arrow::before {
    content: "\f105" !important;
    font-family: "Font Awesome 5 Pro" !important;
    font-size: 1rem !important;
}

.topbar > .topbar-item > .btn-icon {
    & > img {
        filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg) brightness(100%) contrast(102%) !important;
    }

    &:hover {
        & > img {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(66deg) brightness(108%) contrast(98%) !important;
        }
    }
}

.menu-submenu > .menu-subnav > .menu-item > .menu-link {
    & > .menu-bullet {
        color: #494b74;
    }
    & > .menu-icon > img {
        filter: invert(28%) sepia(9%) saturate(2697%) hue-rotate(199deg) brightness(92%) contrast(81%);
    }

    &:hover {
        & > .menu-icon > img {
            filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg) brightness(100%) contrast(102%);
        }

        & > .menu-bullet {
            color: #3699ff;
        }
    }
}

.menu-submenu > .menu-subnav > .menu-item-open > .menu-link {
    & > .menu-icon > img {
        filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg) brightness(100%) contrast(102%);
    }
}

.menu-submenu > .menu-subnav > .menu-item-hover > .menu-link {
    & > .menu-icon > img {
        filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg) brightness(100%) contrast(102%);
    }
}
