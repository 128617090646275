.ModalContentAxionChangeInvoiceType {
  .sub-content {
    &::before {
      content: "";
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      border: 9px;
      border-style: solid;
      border-color: transparent #ffa800 #ffa800 transparent;
      transform: translate(-100%, 60%);
    }
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      z-index: 100;
      left: 1px;
      border: 8px;
      border-style: solid;
      border-color: transparent #fff4de #fff4de transparent;
      transform: translate(-100%, 60%);
    }
  }
}
