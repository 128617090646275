.DropdownHeaderNotification {
  width: 370px;

  &_Header {
    background-color: #ffffff;
    position: sticky;
    top: 0px;
    .VIOverViewBar {
      width: 340px;
      .itemBx.active {
        color: #5180fb;
      }
    }
  }
  &_Content {
    max-height: 60vh;
    overflow: auto;
  }
}
