.Vi-form-check {
  .check {
    padding: 5px 0px;
    input {
      margin-left: 0px;
      cursor: pointer;
      font-size: 16px;
    }
    .form-check-label {
      padding-left: 20px;
      // min-width: max-content;
    }
    .form-check-input {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}
