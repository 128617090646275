.VIDropdownPosition {
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    border: none !important;
  }

  .dropdown-toggle.nav-link:after,
  .dropdown-toggle.btn:after {
    display: none !important;
  }
  .toggle-tax {
    height: 34.5px;
    border-radius: 12px;
    margin-top: 1px !important;
    border: 1px solid #e4e6ef;
    text-align: start;
    font-weight: 500;
    padding: 0.55rem 1rem;
  }
}
