.KTFormTextArea {
  .form-invalid {
    border-color: #e54135;
    box-shadow: 0px 0px 6px 0px #e54135cc !important;
  }

  .form-control {
    border-radius: 12px;
    &:focus {
      border-color: #304ffd;
      box-shadow: 0px 0px 6px 0px #40afffcc !important;
    }
  }
}
