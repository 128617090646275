.vi-invoice {
  .bg-default {
    border-radius: 14px;
    background: var(--system-grey, #f5f5f5);
    // height: 160px;
    width: 100%;
  }
  .form-check-label {
    cursor: pointer !important;
  }
  .form-check-input {
    &:focus {
      box-shadow: none;
    }
    font-size: 16px;
  }
  .title-select {
    color: var(--text-text-3, #7177a8);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 8px;
    display: block;
  }
  .warning {
    padding: 8px;
    border-radius: 12px;
    background: var(--system-grey, #f5f5f5);
    color: var(--secondary-red, #f44141);
    margin-top: 16px;
  }
}
