$darkBlue: #23262f;
$grayBorder: #dbe3ef;
$lightGray: #78809c;

.Tabs_Container {
  overflow-x: auto;
  overflow-y: hidden;
  .tabbed {
    border-bottom: 1px solid $grayBorder;
    width: 100%;
  }
}

.Tabs_Container::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Tabs_Container {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.tabbed .tab {
  background: #fcfcfc;
  padding: 0.7rem 1.6rem 0.7rem 1.6rem;
  position: relative;
  cursor: pointer;
  color: var(--light-grey, #7177a8);
  // border-top: 1px solid #dbe3ef;
  border-left: 1px solid #dbe3ef;
  display: flex;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 0.7rem;
  transition: all 250ms ease;
  max-height: 45px;
}

.tabbed .tab:before {
  display: block;
  content: ' ';
  // border-top: 1px solid #dbe3ef;
  position: absolute;
  top: 0;
  height: 100%;
  width: 2.3rem;
  background: #fcfcfc;
  right: -0.9rem;
  transform: skew(18deg, 0deg);
  border-right: 1px solid #dbe3ef;
  transition: all 250ms ease;
  box-shadow: (8px 4px 16px rgba(44, 63, 88, 0.03));
}

.tabbed .tab:hover,
.tabbed .tab:hover:before {
  color: var(--grey, #535362);
}

.tabbed .tab.active,
.tabbed .active:before {
  background-color: #fff;
  color: var(--grey, #535362);
  z-index: 2;
  border-bottom: 0;
  border-top: 0 !important;
  .tab:before {
    height: 0.6px !important;
  }
}
.tabbed .active:focus-visible {
  outline: none !important;
}
.active {
  .radian-border {
    z-index: 3;
    background-color: #fff;
  }
}
.radian-border {
  right: -26.1px;
  top: 36px;
  width: 12px;
  height: 9px;
  background-color: #fcfcfc;

  .item-radian {
    width: 12px;
    height: 9.1px;
    background-color: #f8f8f9;
    right: -2.6px;
    border-radius: 0 0 0 100%;
    border-left: 1px solid #dbe3ef;
    transform: skew(18deg, 0deg);
  }
}

.tabbed .active:after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: calc(100% + 19px + 0.7rem);
  height: 2px;
  background: white;
  margin-top: -0.5px;
}
.tab:first-child {
  .radian-border {
    .item-radian {
      background-color: #eaeff7 !important;
    }
  }
}

/*----- Round Tabs -----*/
.tabbed.round .tab {
  border-radius: 20px 29px 0 0;
}
.tabbed.round .tab:before {
  border-radius: 0 18px 0 0;
}
