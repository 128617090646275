.snapshot-drag {
  top: 50% !important;
  position: unset !important;
  left: 0 !important;
}
.AppDropDrag {
  min-width: 270px;

  .title-appDrop {
    color: var(--dark, #16161e);
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
  }
  .filter-drop-drag {
    max-height: 60vh;
    overflow: auto;
  }
  input {
    border-radius: 7px;
  }
}
