@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');
/* Custom fonts */

/* @import "./assets/fontawesome5/css/all.css"; */
@import './assets/fontawesome6/css/all.css';
/* fontawesome */

@import './assets/keenthemes-icons/ki.css';
/* ki */

@import './assets/flaticon2/flaticon.css';
/* flaticon2 */

@import '~perfect-scrollbar';
/* perfect scrollbar */

html,
body {
  /* font size of root element (rem) */
  font-size: 13px !important;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-check-input {
  font-size: 15px;
  cursor: pointer;
  margin-top: 2px;
  border-color: rgb(60, 67, 118);
  /* border: 1.5px solid rgb(60, 67, 118); */
}

.form-check {
  margin-bottom: 0;
}

.form-check-label {}