.VIStatisticCard {
  box-shadow: 0px 8px 24px 0px #252a5b0d;
  border-radius: 16px;
  background: #ffffff;
  color: #16161e;
  padding: 20px;
  // width: max-content;

  &_header {
    .text {
      white-space: inherit;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .card-icon {
      height: 64px;
      width: 64px;
      i {
        font-size: 3rem;
        color: inherit;
      }
    }

    .DateRangePickerInput {
      height: 30px;
      max-width: 205px;
      & div {
        // max-width: 70% !important;
      }
      i {
        font-size: 12px;
        max-height: 28px;
      }
      span {
        font-size: 11px;
        // padding-left: 7px;
      }

      // .DateRangePickerInput_Icon_Calender {
      //   margin-top: 6px;
      // }
    }
  }

  &_content {
    border-top: 1px solid #f5f5f5 !important;
    color: #16161e;
  }
}
