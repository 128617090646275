.accordion-payment {
  .accordion-item {
    border: 0;
    margin-bottom: 16px;
  }
  .accordion-button {
    background-color: #f8f8f8 !important;
    border-radius: 16px !important;
    outline: 0;

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    &:not(.collapsed) {
      box-shadow: none;

      // &::after {
      //   background-image: none;
      //   transform-origin: center;
      // }
    }

    // &::after {
    //   content: "\f0d7";
    //   font: var(--fa-font-solid);
    //   font-size: 16px;
    //   background-image: none;
    // }
  }

  .accordion-body {
    background-color: white !important;
    border: 1px solid #e8e9eb;
    margin-top: 8px;
  }
}
