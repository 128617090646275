.VISelect-filter {
  .dropdown-toggle {
    // min-width: max-content;
    // max-width: 100%;
    display: flex;
    border-radius: 14px;
    border: 1px solid var(--grey-40, #dbe3ef) !important;
    background: var(--white, #fff) !important;
    color: var(--dark, #16161e) !important;
    font-size: 14px;
    border-color: var(--stroke, #e8e9eb) !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06) !important;
    &:after {
      display: none !important;
    }
    &:hover {
      background: var(--white, #7c7b7b) !important;
      color: var(--dark, #16161e) !important;
    }
    .icons-down {
      right: 5px;
      color: var(--light-grey, #9a9aaf) !important;
    }
    &:focus {
      border-color: #304ffd !important;
      box-shadow: 0px 0px 6px 0px #40afffcc !important;
    }
    span {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: start;
    }
    .render-checkbox {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: start;
      display: flex;
    }
  }

  .dropdown-menu {
    // max-height: 30vh;
    // overflow-y: auto;
    // overflow-x: hidden;
    padding: 10px 0;
    width: max-content;
    min-width: 130px;
    .Checkbox-items {
      &:hover {
        background: #f5f5f5;
      }
      margin: 0 0.5rem;
      border-radius: 12px;
    }
  }

  .dropdown-item {
    &:active {
      color: #101221 !important;
      background-color: #f3f6f9 !important;
    }
    &:hover {
      background-color: none !important;
    }
    &:hover {
      background: transparent;
    }
    .items-name {
      width: auto;
      &:hover {
        border-radius: 12px;
        background: #f5f5f5;
      }
    }
  }
}
