.LandingPage {
  color: #18214d;
  .text-normal {
    color: #5c6077;
    font-size: 1.5rem;
    font-weight: normal;
    text-align: justify;
    line-height: 150% !important;
  }

  .btn-white {
    color: #7177a8 !important;
    border: 2px solid #7177a8 !important;
  }
  .big-title {
    font-size: 2.6rem;
    font-weight: 700;
    color: #18214d;
    line-height: 1.2 !important;
  }
  .medium-title {
    font-size: 2.6rem;
    font-weight: 700;
    color: #18214d;
    line-height: 1.2 !important;
  }
  &_Introduction {
    background-image: url("/assets/images/img_landingpage_5.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  &_Hotline {
    background-image: url("/assets/images/img_landingpage_7.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  &_Advantage {
    color: #18214d;
    i {
      font-size: 36px;
      color: #1c2256;
    }
  }

  &_Feature {
    color: #18214d;
    i {
      font-size: 24px;
      color: #1c2256;
    }
    width: 100%;

    .feature-banner {
      width: 100%;
      background: linear-gradient(90deg, #153165 3.03%, #12306c 98.45%);
      background-image: url("/assets/images/img_landingpage_6.png");
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100% 50%;
      padding-bottom: 350px !important;
    }
  }
  &_Partners {
    .swiper {
      .swiper-wrapper {
        padding: 0px 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .swiper-slide-active {
        display: flex;
        justify-content: space-between;
      }
      @media (max-width: 576px) {
        .swiper-wrapper {
          padding: 0 50px !important;
          justify-content: center !important;
        }
      }
    }
  }

  &_Characteristic {
    background: #13316b;
    background-image: url("/assets/images/img_landingpage_3.png");
    background-repeat: no-repeat;
    @media (min-width: 576px) {
      background-position: left bottom;
      background-size: 50% 50%;
    }
    @media (max-width: 575px) {
      background-position: top;
      background-size: 100% 50%;
    }
  }

  &_Feedback {
    .swiper-slide {
      .text-normal {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 7; /* number of lines to show */
        line-clamp: 7;
        -webkit-box-orient: vertical;
      }
      .feedback-position {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .rounded-circle {
      height: 45px !important;
      width: 45px !important;
      gap: 11px;
    }
  }
  .swiper {
    .swiper-wrapper {
      height: max-content;
    }
    .swiper-slide {
      height: auto;
    }
  }
  .fixed-button {
    position: fixed;
    bottom: 30px;
    right: 10px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    @media screen and (max-width: 568px) {
      right: 5px;
      transform: scale(0.7);
      bottom: 15px;
    }

    button {
      background: transparent;
      border: 0px;
    }
    .tel {
      img:hover {
        opacity: 0.85;
      }
    }
    #page-up {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .phone {
    display: flex;
    justify-content: center;
    opacity: 1;
    animation-name: phone-animation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    cursor: pointer;
    // margin-left: 10px;
  }

  .circle {
    border-radius: 100%;
    opacity: 0.1;
    animation: circle-fill-animation 1.5s infinite ease-in-out;
    position: fixed;
    bottom: 135px;
    right: 17px;
    width: 140px;
    height: 140px;
    border: 2px solid #1b6ae8;
  }

  .circle-fill {
    background: #1b6ae8;
    border-radius: 100%;
    opacity: 0.75;
    position: fixed;
    bottom: 108px;
    right: 8px;
    width: 90px;
    height: 90px;
    border: 2px solid #1b6ae8;
    animation: circle-fill-animation 1.5s infinite ease-in-out;
    @media screen and (max-width: 568px) {
      right: -8px;
      transform: scale(0.7);
      bottom: 100px;
    }
  }

  @keyframes phone-animation {
    from {
      transform: rotate3d(0, 0, 1, 0deg);
    }
    20%,
    32%,
    44%,
    56%,
    68% {
      transform: rotate3d(0, 0, 1, 0deg);
    }
    23%,
    35%,
    47%,
    59%,
    71% {
      transform: rotate3d(0, 0, 1, 15deg);
    }
    26%,
    38%,
    50%,
    62%,
    74% {
      transform: rotate3d(0, 0, 1, 0deg);
    }
    29%,
    41%,
    53%,
    65%,
    77% {
      transform: rotate3d(0, 0, 1, -15deg);
    }
    80% {
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }

  @keyframes circle-fill-animation {
    0% {
      transform: rotate(0) scale(0.7) skew(1deg);
      opacity: 0.2;
    }
    50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: 0.2;
    }
    100% {
      transform: rotate(0) scale(0.7) skew(1deg);
      opacity: 0.2;
    }
  }
  @keyframes scroll-top-animation {
    0% {
      margin-bottom: 0px;

      opacity: 0;
    }
    100% {
      margin-bottom: 30px;
      opacity: 1;
    }
  }

  @media screen and (min-width: 1440px) {
    .big-title {
      font-size: 3rem;
    }
    .medium-title {
      font-size: 3rem;
    }
  }

  @media (min-width: 769px) and (max-width: 1439px) {
    .text-normal {
      font-size: 1.1rem;
    }
  }

  @media (min-width: 576px) and (max-width: 768px) {
    .text-normal {
      font-size: 1rem;
    }
  }

  @media (max-width: 575px) {
    .text-normal {
      font-size: 0.9rem;
    }
  }
  @media (max-width: 575px) {
    .right-hotline {
      justify-content: center !important;
    }
  }
}
