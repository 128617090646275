.LandingPageHeader {
  position: fixed;
  top: 0;
  z-index: 10;
  .header-tab {
    &:hover {
      color: #5180fb !important;
      border-radius: 10px !important;
    }
  }
  transition: ease-in-out;
  &.header-scroll {
    background: #ffffff;
    box-shadow: 5px -1px 12px -5px grey;
  }
  &_mobile {
    display: none !important;
  }

  @media (max-width: 767px) {
    &_laptop {
      display: none !important;
    }
    &_mobile {
      display: block !important;
      .dropdown-toggle {
        background: none !important;
        border: 0px !important;
        &:focus,
        &:hover,
        &:active {
          i {
            color: #5180fb !important;
          }
        }
      }
      .dropdown-toggle::after {
        display: none !important;
      }
      .dropdown-menu {
        // margin-top: 15px !important;
        // border-radius: 0px !important;
      }
    }
  }
}
