.VIInputField {
  .form-control {
    border-radius: 12px;
    &:focus {
      border-color: #304ffd;
      box-shadow: 0px 0px 6px 0px #40afffcc !important;
    }
  }

  .form-control:focus + .email-input {
    border-color: #304ffd;
    box-shadow: 0px 0px 6px 0px #40afffcc !important;
  }
  .email-input {
    margin-top: 1px !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-left: -121px;
    width: 120px;
    border: 0px;
    background: #eaf4ff;
    color: #304ffd;
    border-left: 1px solid #e4e6ef;
    font-weight: 500;
    padding: 0.55rem 1rem;
  }

  .error-input {
    border-color: #e54135;
    box-shadow: 0px 0px 6px 0px #e54135aa !important;
  }

  .error-input + .email-input {
    border-color: #e54135;
  }
}
