.DateRangePickerInput {
  height: 39.95px;
  border: 1px solid #dbe3ef;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  background: linear-gradient(180deg, #ffffff 0%, #fcfcff 100%);
  width: max-content;
  span {
    color: #4a5677;
  }

  &:focus-within {
    border-color: #304ffd !important;
    box-shadow: 0px 0px 6px 0px #40afffcc !important;
  }

  input {
    border: none;
    font-weight: 500;
    color: var(--remaining-color);
  }

  &:hover {
    background: linear-gradient(180deg, #ffffff 0%, #fcfcff 100%);
  }
  &:active {
    background: linear-gradient(180deg, #f8f9ff 0%, #f1f2f5 100%);
  }
}
.daterangepicker {
  // margin-top: 5px !important;
}
@media (max-width: 1280px) {
  .DateRangePickerInput {
    min-width: 110px;
  }
}
@media (max-width: 751px) {
  .DateRangePickerInput {
    min-width: 118px;
  }
}
.ranges {
  overflow: auto;
  ul li {
    &:hover {
      background-color: #f3f6f9 !important;
    }
    &:active {
      background-color: #f3f6f9 !important;
    }
    &:focus {
      background-color: #f3f6f9 !important;
    }
  }
}
