.ServicePackCard {
  .medium-title {
    // font-size: 5rem;
    font-weight: 700;
    color: #18214d;
    line-height: 1.2 !important;
  }

  .btn-white {
    color: #4570fe !important;
    border: 2px solid #4570fe !important;
  }
  &:hover > .btn-white {
    background: #4570fe !important;
    color: #ffffff !important;
    border: 1px solid #4570fe !important;
  }

  .recommend-label {
    background: #ffa318;
    font-size: 14px;
    position: absolute;
    top: 0px;
    border-radius: 0px 0px 12px 12px;
    left: 25%;
  }
  @media (max-width: 992px) {
    .recommend-label {
      font-size: 13px;
    }
  }
  @media (max-width: 767px) {
    .recommend-label {
      font-size: 12px;
      left: 12.5%;
    }
  }
  @media screen and (min-width: 1440px) {
    .medium-title {
      font-size: 54px;
    }
  }
}
