.accordion-list {
  overflow-y: auto;
  padding-right: 10px;
  .accordion-item {
    border-bottom: 1px dashed #ebedf3;

    .accordion-button {
      font-weight: 500;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 21px */

      /* Dark Grey */

      color: #1c2256;
      padding-left: 35px;
      &::after {
        position: absolute;
        left: 5px;
        background-image: url("../../../../assets/icons/icon-add-question.png");
        background-size: contain;
        padding: 10px;
      }
      &:focus {
        border-color: unset;
        box-shadow: unset;
      }

      &:not(.collapse) {
        box-shadow: none;
      }
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("../../../../assets/icons/icon-remove-question.png");
    }
    .accordion-button:not(.collapsed) {
      background-color: unset;
    }
    .accordion-body {
      background: #f8f8f8;
      /* Line */

      border: 1px solid #e4e6ef;
      padding: 20px;
      margin-bottom: 16px;
      border-radius: 8px;
      font-weight: normal;
      font-size: 1rem;
      line-height: 150%;
      /* or 21px */

      /* Dark Grey */

      color: #1c2256;
      position: relative;
      white-space: break-spaces;
      word-break: break-work;
    }
  }
}
