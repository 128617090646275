.VIPagination {
  .dropdown-toggle:after {
    display: none !important;
  }
  .btn.btn-outline-secondary:hover:not(.btn-text):not(:disabled):not(.disabled),
  .btn.btn-outline-secondary:focus:not(.btn-text),
  .btn.btn-outline-secondary.focus:not(.btn-text) {
    border-radius: 14px;
    border: 1px solid var(--primary-blue, #5180fb);
    background: var(--system-white, #fff);
    box-shadow: 0px 0px 4px 0px rgba(61, 103, 255, 0.5);
    & > i {
      color: #5180fb !important;
    }
  }
  @media screen and (max-width: 650px) {
    .pagination-flex {
      flex-direction: column !important;
    }
    .page-pagination {
      margin-top: 5px;
    }
  }
}
