.header-mobile .btn > .svg-icon {
    & > img {
        filter: invert(30%) sepia(11%) saturate(986%) hue-rotate(189deg) brightness(96%) contrast(94%);
    }

    &:hover > img {
        filter: invert(45%) sepia(10%) saturate(5000%) hue-rotate(150deg) brightness(130%) contrast(150%);
    }
}

.header-mobile .btn.active {
    filter: invert(45%) sepia(10%) saturate(5000%) hue-rotate(150deg) brightness(130%) contrast(150%);
    border: none;
}

.KT01HeaderMobile {
    .header-mobile {
    }
}
