.CardResourceInformation {
  background-image: url(../../../../assets/images/img_resource_info.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 14px;
  &_data {
    overflow-wrap: break-word;
  }
  @media (max-width: 680px) and (min-width: 576px) {
    button {
      font-size: 14px !important;
    }
    &_data {
      div {
        font-size: 12px;
      }
      p {
        font-size: 20px !important;
      }
    }
  }
}
