.Input-invoice {
  .show-func {
    margin-top: -40px;
  }
  @media screen and (max-width: 770px) {
    .show-func {
      margin-top: -20px !important;
    }
  }
  @media screen and (max-width: 540px) {
    .Filter-desktop {
      display: none !important;
    }
    .show-filters-mobile {
      display: block !important;
    }
  }
}
