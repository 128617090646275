.LandingPageFooter {
  font-weight: 400;
  font-size: 14px;
  color: #5c6077;

  &_Copyright {
    height: 60px;
    margin-top: 50px;
    padding-top: 18px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    text-align: center;
    color: #5c6077;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }

  &_Title {
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: #18214d;
  }
  &_Nav {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    row-gap: 10px;
    .LandingPageFooterNavItem {
      cursor: pointer;
      :hover {
        color: #0f31d7;
      }
    }
  }

  .FooterDashboardUnderline {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: '768px') {
    .LandingPageFooterSection {
      margin-top: 40px !important;
    }
  }
}
