.VI-body-icons {
  .icons {
    margin-right: 10px;
    width: 20px;
    i {
      color: #3c4376;
    }
    img {
      filter: brightness(0) saturate(100%) invert(23%) sepia(13%) saturate(2713%) hue-rotate(195deg)
        brightness(95%) contrast(83%);
    }
  }
  span {
    min-width: max-content;
    color: #3c4376;
  }
  .items-icon {
    cursor: pointer;
    padding: 8px 10px;
    color: var(--text-text-2, #3c4376);

    &:hover {
      border-radius: 12px;
      background: #f5f5f5;
    }
  }
}
