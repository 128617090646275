.CellNotification {
  border-radius: 8px;
  &_Thumbnail {
    width: 55px;
    height: 55px;
    img {
      object-fit: cover;
      border-radius: 8px;
      width: 55px;
      height: 55px;
    }
  }
  .noti-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .noti-date {
    color: #7177a8;
  }
  &:hover {
    background: #f8f8f8;
  }
}
