.Input-invoice {
  .show-func {
    margin-top: -40px;
  }
  .xml-upload {
    &:hover {
      img {
        display: none !important;
      }
      .icon-up {
        display: block !important;
        font-size: 20px !important;
      }
    }
  }
  .pdf-upload {
    &:hover {
      img {
        display: none !important;
      }
      .icon-up {
        display: block !important;
        font-size: 21px !important;
        margin-right: 13px !important;
      }
    }
  }
  @media screen and (max-width: 1290px) {
    .show-func {
      margin-top: -20px !important;
    }
  }
  .DropdownFilter {
    padding: 8px;
    align-items: center;
    border-radius: 14px;
    border: 1px solid var(--system-blue-grey, #dde5ee);
    cursor: pointer;
    // &:focus,
    // &:focus-within,
    // &:active,
    // &:target {
    //   border-color: #304ffd;
    //   box-shadow: 0px 0px 6px 0px rgba(64, 175, 255, 0.8) !important;
    // }
  }
  @media screen and (max-width: 540px) {
    .Filter-desktop {
      display: none !important;
    }
    .show-filters-mobile {
      display: block !important;
    }
  }
}
