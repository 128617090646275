.snapshot-drag {
  // top: 50% !important;
  // position: unset !important;
  // left: 0 !important;
}
.VIDragAndDropV2 {
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    // border: none !important;
    background-color: transparent !important;
  }
  .dropdown-toggle.nav-link:after,
  .dropdown-toggle.btn:after {
    display: none !important;
  }
}
